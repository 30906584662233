
import { defineComponent, ref } from "vue";
import * as ClickServices from "../api/helpers/Click";
import { useGtm } from "vue-gtm";
import * as ApplicationServices from "../api/helpers/Application";
import * as CardServices from "../api/helpers/Cards";
import * as FacebookServices from "../api/helpers/Facebook";
import Spinner from "../components/Spinner.vue";
import Cards from "../components/Cards.vue";
import { getCurrentInstance } from "vue";
import TheRedirectResult from "@/components/TheRedirectResult.vue";

export default defineComponent({
  name: "Results",
  components: {
    Spinner,
    Cards,
    TheRedirectResult,
  },
  data() {
    return {
      newEmail: sessionStorage.getItem("newEmail"),
      leadId: sessionStorage.getItem("leadId"),
      applicationId: sessionStorage.getItem("applicationId"),
      sourceId: sessionStorage.getItem("CHID"),
    };
  },
  setup() {
    const app = getCurrentInstance();
    const toastTime = app?.appContext.config.globalProperties.$toastTime;
    const toast = app?.appContext.config.globalProperties.$toast;
    var cards = ref([]);
    var loading = ref(false);
    const getCards = async (leadId: string) => {
      loading.value = true;
      await CardServices.find(leadId).then(
        (res) => {
          let { data } = res.data;
          data = data.map((c: any) => {
            c.content = c.content.split(String.fromCharCode(10));
            return c;
          });
          cards.value = data;
          loading.value = false;
        },
        (err) => {
          loading.value = false;
          toast.add({
            severity: "error",
            summary: err,
            life: toastTime,
          });
        }
      );
    };

    return {
      cards,
      getCards,
      loading,
      toastTime,
    };
  },
  mounted() {
    this.loading = true;
    window.scrollTo(0, 0);
    document.title = process.env.VUE_APP_TITLE + " | Resultados";
    FacebookServices.pageView();
    if (this.leadId) this.getCards(this.leadId);

    if (this.newEmail != null) {
      window.dataLayer?.push({
        leadId: this.leadId,
        applicationId: this.applicationId,
        newEmail: this.newEmail,
        eventID: sessionStorage.getItem("eventID"),
      });
      FacebookServices.lead();
      this.triggerEvent();
      sessionStorage.removeItem("newEmail");
    }

    const application = this.applicationId?.toString();
    ApplicationServices.update({ completed: true }, application);
  },
  beforeCreate() {
    const leadId = sessionStorage.getItem("leadId");
    const applicationId = sessionStorage.getItem("applicationId");
    if (!leadId || !applicationId) {
      this.$router.push("../cuestionario");
    }
  },
  methods: {
    handleOnClick(cardId: number) {
      var params = {
        leadId: this.leadId,
        cardId: cardId,
        source: this.sourceId,
      };
      ClickServices.click(params);
    },
    triggerEvent() {
      const gtm = useGtm();
      gtm?.trackEvent({
        event: "FireGTM",
      });
    },
  },
});
