
import { PropType, defineComponent } from "vue";
import * as ClickServices from "../api/helpers/Click";
import Spinner from "../components/Spinner.vue";

interface Card {
  activeDays: any[];
  id: number;
  name: string;
  content: string[];
  aside: string;
  b64Picture: string;
  order: null;
  active: boolean;
  startTimeActivity: null;
  endTimeActivity: null;
  subtitle: null;
  starred: boolean;
  createdAt: string;
  updatedAt: string;
  systemId: number;
  offerTypeId: null;
  rules: any[];
  settings: any[];
  urls: URL[];
}

interface URL {
  id: number;
  url: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  cardId: number;
}

export default defineComponent({
  components: {
    Spinner,
  },
  props: {
    card: {
      type: Object as PropType<Card>,
      required: true,
    },
    leadId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.handleClick(Number(this.leadId), this.card.id, this.card.urls[0].url);
  },
  methods: {
    async handleClick(leadId: number, cardId: number, url: string) {
      try {
        const body = {
          leadId: leadId,
          cardId: cardId,
          source: sessionStorage.getItem("CHID"),
        };
        await ClickServices.click(body);
        window.open(url, "_self");
      } catch (error) {
        console.log(error);
      }
    },
  },
});
